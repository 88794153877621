import * as React from "react"
import CssBaseline from "@mui/material/CssBaseline"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Container from "@mui/material/Container"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { styled, alpha } from "@mui/material/styles"

import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Paper from "@mui/material/Paper"
import List from "@mui/material/List"
import SearchIcon from "@mui/icons-material/Search"
import InputBase from "@mui/material/InputBase"
import Button from "@mui/material/Button"
import HomeIcon from "@mui/icons-material/Home"
import ViewComfyIcon from "@mui/icons-material/ViewComfy"
import IconButton from "@mui/material/IconButton"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { navigate } from "gatsby"


export default function SiteHeader({ children}) {
  return (
    <React.Fragment>
      <AppBar position="fixed">
        <Toolbar>
          <Link to="/">
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <ViewComfyIcon />
            </IconButton>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
            <SearchIcon />
            </IconButton>

          </Link>
        </Toolbar>
      </AppBar>
      {children}
    </React.Fragment>
  )
}
